import * as styles from '../sections/landing1/Home.module.css'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button, Text } from './Core'
import { Row } from 'react-bootstrap'
import loadable from '@loadable/component'
import theme from '../utils/theme'
import {
  fetchBaseCurrExchRate,
  fetchQuoteCurrExchRate,
} from '../store/actions/marketsActions'
import Logo from '../components/Logo'
import {
  fetchAuthIds,
  fetchUserPrefs,
  placeOrder,
  fetchOrderTypes,
} from '../store/actions/userActions'
import { isDecimal } from '../utils/helperFn'

const Select = loadable(() => import('react-select'))

const NewOrderForm = props => {
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''
  
  const dispatch = useDispatch()
  const { t, ready } = useTranslation(['tables'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userPrefs = useSelector(state => state.user.userPrefs)
  const authIds = useSelector(state => state.user.authIds)
  // const authExchIds = useSelector(state => state.user.authExchIds)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  // const baseCurrExchRate = useSelector(state => state.markets.baseCurrExchRate)
  const quoteCurrExchRate = useSelector(
    state => state.markets.quoteCurrExchRate
  )
  const orderResponse = useSelector(state => state.user.orderResponse)

  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const userCurrency = useSelector(state => state.user.selectedCurr)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const [priceInput, setPriceInput] = useState(props.basePrice)
  const [quoteCurrInput, setQuoteCurrInput] = useState('')
  const [baseCurrInput, setBaseCurrInput] = useState('')
  const [userCurrInput, setUserCurrInput] = useState('')
  const [orderAuthId, setOrderAuthId] = useState(
    typeof authIds[0] !== 'undefined' ? authIds[0] : false
  )

  useEffect(() => {
    if (props.basePrice > 1) {
      setPriceInput(Number(props.basePrice).toFixed(2))
    }
    if (props.basePrice < 1) {
      setPriceInput(props.basePrice)
    }
  }, [props.basePrice])

  useEffect(() => {
    if (typeof authIds[0] !== 'undefined') {
      setOrderAuthId(authIds[0].authId)
    }
  }, [authIds])

  useEffect(() => {
    dispatch(fetchBaseCurrExchRate(props.baseCurr, userCurrency))
    dispatch(fetchQuoteCurrExchRate(props.quoteCurr, userCurrency))
  }, [props.baseCurr, props.quoteCurr, userCurrency])

  //recalculate User Currency Input when user changes currency
  useEffect(() => {
    setUserCurrInput(
      Number(priceInput * baseCurrInput * quoteCurrExchRate).toFixed(2)
    )
  }, [quoteCurrExchRate])

  useEffect(() => {
    if (userSessionExpired === false) {
      dispatch(fetchAuthIds(props.exchId))
      dispatch(fetchUserPrefs())
      dispatch(fetchOrderTypes())
    }
  }, [dispatch, userSessionExpired])

  const [orderTriggered, setOrderTriggered] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOrderTriggered(false)
    }, 9000)
    return () => clearTimeout(timer)
  }, [orderTriggered])

  const orderHandler = async () => {
    await dispatch(
      placeOrder(
        newToken,
        orderAuthId,
        props.exchmktId,
        orderType,
        orderSide,
        priceInput,
        Number(baseCurrInput)
      )
    )
    await setOrderTriggered(true)
  }

  const handlePriceInputChange = async value => {
    if (!isDecimal(value)) return

    await setPriceInput(value)
    await setQuoteCurrInput(value * baseCurrInput)
    await setUserCurrInput(
      Number(value * baseCurrInput * quoteCurrExchRate).toFixed(2)
    )
  }
  const handleUserCurrInputChange = async value => {
    if (!isDecimal(value)) return

    await setUserCurrInput(Number(value).toFixed(2))
    await setQuoteCurrInput(value / quoteCurrExchRate)
    await setBaseCurrInput(value / quoteCurrExchRate / priceInput)
  }
  const handleQuoteCurrInputChange = async value => {
    if (!isDecimal(value)) return

    await setQuoteCurrInput(value)
    await setUserCurrInput(Number(value * quoteCurrExchRate).toFixed(2))
    await setBaseCurrInput(value / priceInput)
  }
  const handleBaseCurrInputChange = async value => {
    if (!isDecimal(value)) return

    await setBaseCurrInput(value)
    await setUserCurrInput(
      Number(value * priceInput * quoteCurrExchRate).toFixed(2)
    )
    await setQuoteCurrInput(value * priceInput)
  }

  const handleOrderTypeSelector = async value => {
    await setOrderType(value)
    if (priceInput !== props.basePrice) {
      await setPriceInput(props.basePrice)
    }
  }

  const orderSideOptions = [
    { value: 1, label: 'Buy' },
    { value: 2, label: 'Sell' },
  ]
  const orderTypeOptions = [
    // { value: 5, label: 'Market' },
    { value: 3, label: 'Limit' },
  ]
  const [orderSide, setOrderSide] = useState(1)
  const [orderType, setOrderType] = useState(orderTypeOptions[0].value)
  const getCustomStyles = theme => {
    return {
      option: (provided, state) => ({
        ...provided,
        color: userDarkMode ? '#FAFAFA' : 'rgba(0, 0, 0, 0.56)',
        textAling: 'Left',
        backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
        fontSize: 16,
        paddingRight: 20,
        paddingLeft: 20,
        textAlign: 'Left',
        zIndex: 1,
        '&:hover': {
          backgroundColor: userDarkMode
            ? 'rgb(43, 47, 54)'
            : 'rgba(0, 0, 0, 0.04)',
        },
      }),
      control: (provided, state) => {
        return {
          ...provided,
          backgroundColor: userDarkMode
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
          border: `0px solid transparent !important`,
          marginBottom: 2,
          marginTop: 2,
          paddingRight: 10,
          paddingLeft: 10,
          zIndex: 0,
          fontSize: 16,
          width: '130px',
          outline: 'none',
          height: 44,
          minHeight: 20,
          borderRadius: 22,
          boxShadow: 'none',
          color: userDarkMode
            ? 'rgba(255, 255, 255, 0.56)'
            : 'rgba(0, 0, 0, 0.56)',
          '&:hover': {
            backgroundColor: userDarkMode
              ? 'rgb(43, 47, 54)'
              : 'rgba(0, 0, 0, 0.04)',
          },
        }
      },
      menu: provided => ({
        ...provided,
        borderRadius: 12,
      }),
      menuList: provided => ({
        ...provided,
        backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
        borderRadius: 10,
      }),
      singleValue: (provided, state) => {
        const color = 'inherit'
        const opacity = state.isDisabled ? 0.5 : 1
        const transition = 'opacity 300ms'

        return { ...provided, color, opacity, transition }
      },
    }
  }
  const authOptions = []

  if (authIds.length > 0) {
    for (const id in authIds) {
      authOptions.push({
        value: authIds[id].authId,
        label: authIds[id].authNickname,
      })
    }
  }

  return ready && (
    <Box
      style={{
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 10,
        backgroundColor: userDarkMode ? '#191B20' : '#E5E5E5',
        padding: '10px 15px 15px',
        width: '350px',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Row className="mt-2 mb-3 ml-2">
        <Logo className="mr-4" white={userDarkMode} />
        {authOptions.length > 0 ? (
          <Select
            isSearchable={false}
            className="mr-4 ml-3"
            options={authOptions}
            styles={getCustomStyles(theme)}
            borderRadius={0}
            defaultValue={authOptions[0]}
            onChange={option => setOrderAuthId(option.value)}
            components={{ IndicatorSeparator: () => null }}
          />
        ) : null}
      </Row>

      <Row>
        <Select
          isSearchable={false}
          className="mr-4 ml-3"
          options={orderSideOptions}
          styles={getCustomStyles(theme)}
          borderRadius={0}
          defaultValue={orderSideOptions[0]}
          onChange={option => setOrderSide(option.value)}
          components={{ IndicatorSeparator: () => null }}
        />
        <Select
          isSearchable={false}
          options={orderTypeOptions}
          styles={getCustomStyles(theme)}
          borderRadius={0}
          defaultValue={orderTypeOptions[0]}
          onChange={option => handleOrderTypeSelector(option.value)}
          components={{ IndicatorSeparator: () => null }}
        />
      </Row>
      <div className="mt-2">
        <Text variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
          {t('price')}{' '}
        </Text>
        <input
          style={{
            height: '44px',
            borderRadius: 6,
            margin: '6px 0px',
          }}
          value={priceInput}
          onChange={e => handlePriceInputChange(e.target.value)}
          type="text"
          className="form-control"
          placeholder={t('enterAmount')}
          disabled={orderType === 3 ? false : true}
          //   onKeyPress={e => handleKeypress(e)}
        />
      </div>
      <div className="mt-2">
        <Text variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
          {props.quoteCurr}{' '}
        </Text>
        <input
          style={{
            height: '44px',
            borderRadius: 6,
            margin: '6px 0px',
          }}
          value={quoteCurrInput}
          onChange={e => handleQuoteCurrInputChange(e.target.value)}
          type="text"
          className="form-control"
          placeholder={t('enterAmount')}
          //   onKeyPress={e => handleKeypress(e)}
        />
      </div>
      {userCurrency !== props.quoteCurr ? (
        <div className="mt-2">
          <Text variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
            {userCurrency}{' '}
          </Text>
          <input
            style={{
              height: '44px',
              borderRadius: 6,
              margin: '6px 0px',
            }}
            value={userCurrInput}
            onChange={e => handleUserCurrInputChange(e.target.value)}
            type="text"
            className="form-control"
            placeholder={t('enterAmount')}
            //   onKeyPress={e => handleKeypress(e)}
          />
        </div>
      ) : null}

      <div className="mt-2">
        <Text variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
          {props.baseCurr}{' '}
        </Text>
        <input
          style={{
            height: '44px',
            borderRadius: 6,
            margin: '6px 0px',
          }}
          value={baseCurrInput}
          onChange={e => handleBaseCurrInputChange(e.target.value)}
          type="text"
          className="form-control"
          placeholder={t('enterAmount')}
          //   onKeyPress={e => handleKeypress(e)}
        />
      </div>
      {userSessionExpired === true ? (
        <Row style={{ justifyContent: 'center' }}>
          <a
            data-testid="loginButton"
            className={styles.aTagButtonSmall}
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.LS_LOGIN}
            aria-label={t('signup')}
          >
            {t('login')} {t('toPlaceOrder')}
          </a>
        </Row>
      ) : userPrefs.lscxVerified === true && props.exchId === 91 ? (
        <Button
          data-testid="placeOrderButton"
          bg={orderSide === 1 ? '#2D844B' : '#D0433B'}
          style={{ width: '100%', height: '44px' }}
          onClick={() => orderHandler()}
        >
          {orderSide === 1 ? `Buy ${props.baseCurr}` : `Sell ${props.baseCurr}`}
        </Button>
      ) : (
        <Row style={{ justifyContent: 'center' }}>
          <a
            data-testid="getLscxAccountButton"
            className={styles.aTagButtonSmall}
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.WWW_URL + `/auth/login`}
            aria-label={t('signup')}
          >
            {t('getLSCXAccount')}
          </a>
        </Row>
      )}
      {orderResponse !== false && orderTriggered === true ? (
        <div className="m-2">
          <Text style={{ color: '#3377c6' }} variant="small">
            {orderResponse.success === true
              ? t('orderSuccessful')
              : orderResponse.error}
          </Text>
        </div>
      ) : null}
    </Box>
  )
}

export default NewOrderForm
