import * as styles from '../../../exchangeDetail.module.css'
import React, { useEffect, useState, useCallback } from 'react'
import loadable from '@loadable/component'
import MetaData from '../../../../components/MetaData'
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap'
import { Section, Title, Text, Box, Input } from '../../../../components/Core'
import PageWrapper from '../../../../components/PageWrapper'
import { useSelector, useDispatch } from 'react-redux'
import Highcharts from 'highcharts/highstock'
import Indicators from 'highcharts/indicators/indicators-all.js'
import FullScreen from 'highcharts/modules/full-screen.js'
import StockTools from 'highcharts/modules/stock-tools.js'
import * as marketsActions from '../../../../store/actions/marketsActions'
import * as currenciesActions from '../../../../store/actions/currenciesActions'
import * as userActions from '../../../../store/actions/userActions'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Link from '../../../../components/Link'
import styled from 'styled-components'
import Exporting from 'highcharts/modules/exporting.js'
import { debounce } from 'lodash'
import { suggestEdit } from '../../../../store/actions/userActions'
import EditIcon from '../../../../assets/image/svg/ic_pencil.inline.svg'
import NewOrderForm from '../../../../components/NewOrderForm'

const HighchartsReact = loadable(() => import('highcharts-react-official'))

const LatestNews = loadable(() =>
  import('../../../../sections/news/LatestNews')
)
const Select = loadable(() =>
  import('../../../../components/Table/RangeSelector')
)

const LinkedCard = styled(Link)`
  box-shadow: 0 2px 4px rgba(14, 86, 124, 0.17);
  background-color: white;
  display: flex;
  padding: 26px 30px;
  transition: 0.4s;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 32px 84px rgba(14, 86, 124, 0.17);
    color: #0056b3 !important;
    text-decoration: none;
    .title {
      color: #0056b3 !important;
    }
  }
`

const BoxShadow = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 15px 8px 15px;
  box-shadow: 0 42px 54px rgba(65, 65, 101, 0.16);
`

const NoDataCard = ({ color = 'primary', title, children, ...rest }) => {
  const { t, ready } = useTranslation( ['tables'] , { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : userSettings.dark_mode
  
  return ready && (
    <div className={styles.noDataCard}>
      <Text color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
        ⚠ {t('noChartMarketData')}
      </Text>
    </div>
  )
}

const MarketDetail = ({ location }) => {
  const dispatch = useDispatch()
  const { t, i18n, ready } = useTranslation(['cryptocurrencies', 'tables'], { useSuspense: false });
  const selectedMarket = useSelector(state => state.markets.marketDetail)
  const realData = useSelector(state => state.markets.ohlc)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const userCurrency = useSelector(state => state.user.selectedCurr)
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const userCurrSign = useSelector(state => state.user.currSign)
  const userHighChRange = useSelector(state => state.user.highChartsRange)
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  let currSign
  let quoteSlug = selectedMarket.quoteCurrencyName
    ? selectedMarket.quoteCurrencyName
    : ''
  let baseSlug = selectedMarket.baseCurrencyName
    ? selectedMarket.baseCurrencyName
    : ''

  selectedMarket.quoteCurrencyCode === 'EUR'
    ? (currSign = '€')
    : selectedMarket.quoteCurrencyCode === 'JPY'
    ? (currSign = '¥')
    : selectedMarket.quoteCurrencyCode === 'GBP'
    ? (currSign = '£')
    : selectedMarket.quoteCurrencyCode === 'KRW'
    ? (currSign = '₩')
    : selectedMarket.quoteCurrencyCode === 'RUB'
    ? (currSign = '₽')
    : selectedMarket.quoteCurrencyCode === 'BRL'
    ? (currSign = 'R$')
    : selectedMarket.quoteCurrencyCode === 'ETH'
    ? (currSign = 'eth')
    : selectedMarket.quoteCurrencyCode === 'BTC'
    ? (currSign = 'btc')
    : (currSign = selectedMarket.quoteCurrencyCode)

  let locationArr = location.pathname.split('/')
  let locationExchSlug = locationArr[locationArr.length - 4]
  let locationBaseCurr = locationArr[locationArr.length - 3]
  let locationQuoteCurr = locationArr[locationArr.length - 2]
  let locationMarketSlug = locationBaseCurr + '/' + locationQuoteCurr
  let locationReversedMarketSlug = locationQuoteCurr + '/' + locationBaseCurr

  // SUGGEST EDIT CODE
  const [openTextArea, setOpenTextArea] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const request = debounce(value => {
    setInputValue(value)
  }, 1000)
  const debouceRequest = useCallback(value => request(value), [request])
  const onChange = e => {
    debouceRequest(e.target.value)
  }

  const editDescOptions = [
    { label: ready && `${t(`missingData`)}`, value: 0 },
    { label: ready && `${t(`incorrectData`)}`, value: 1 },
    { label: ready && `${t(`other`)}`, value: 2 },
  ]
  const [editType, setEditType] = useState(0)

  const editCurrDesc = () => {
    if (inputValue !== null) {
      dispatch(suggestEdit(newToken, editType, location.href, inputValue))
      setOpenTextArea(false)
      setInputValue(null)
    } else {
      alert('please enter a valid message!')
    }
  }

  useEffect(() => {
    dispatch(
      marketsActions.fetchMarketDetail(
        locationExchSlug,
        locationBaseCurr,
        locationQuoteCurr
      )
    )
  }, [
    dispatch,
    locationExchSlug,
    locationMarketSlug,
    locationBaseCurr,
    locationQuoteCurr,
  ])

  useEffect(() => {
    if (selectedMarket.displayName !== selectedMarket.marketName) {
      dispatch(
        marketsActions.fetchMarketOHLC(
          locationExchSlug,
          locationReversedMarketSlug
        )
      )
      const interval = setInterval(() => {
        dispatch(
          marketsActions.fetchMarketOHLC(
            locationExchSlug,
            locationReversedMarketSlug
          )
        )
        dispatch(
          marketsActions.fetchMarketDetail(
            locationExchSlug,
            locationBaseCurr,
            locationQuoteCurr
          )
        )
        dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
      }, 90000)
      return () => clearInterval(interval)
    }
  }, [
    dispatch,
    selectedMarket.displayName,
    selectedMarket.marketName,
    locationExchSlug,
    locationReversedMarketSlug,
    locationBaseCurr,
    locationQuoteCurr,
    userCurrency,
  ])

  useEffect(() => {
    if (selectedMarket.displayName === selectedMarket.marketName) {
      dispatch(
        marketsActions.fetchMarketOHLC(locationExchSlug, locationMarketSlug)
      )
      const interval = setInterval(() => {
        dispatch(
          marketsActions.fetchMarketOHLC(locationExchSlug, locationMarketSlug)
        )
        dispatch(
          marketsActions.fetchMarketDetail(
            locationExchSlug,
            locationBaseCurr,
            locationQuoteCurr
          )
        )
        dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
      }, 90000)
      return () => clearInterval(interval)
    }
  }, [
    dispatch,
    selectedMarket.displayName,
    selectedMarket.marketName,
    locationExchSlug,
    locationMarketSlug,
    locationBaseCurr,
    locationQuoteCurr,
    userCurrency,
  ])

  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth)
      window.onresize = () => {
        setWindowWidth(window.innerWidth)
      }
    }
  }, [])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  let volumeColor

  // IF check to avoid Highcharts error on gatsby build
  if (typeof window !== `undefined`) {
    Indicators(Highcharts)
    FullScreen(Highcharts)
    StockTools(Highcharts)
    Exporting(Highcharts)
  }

  var ohlc = [],
    volume = [],
    dataLength = realData.length,
    // groupingUnits = [
    //   [
    //     "week", // unit name
    //     [1] // allowed multiples
    //   ],
    //   [
    //       "day", // unit name
    //       [1] // allowed multiples
    //   ],
    //   [
    //       "month", // unit name
    //       [1, 2, 3, 4, 5, 6] // allowed multiples
    //   ]
    // ],
    i = 0

  for (i; i < dataLength; i += 1) {
    let timestampDate = new Date(realData[i].endTime).getTime()
    volumeColor = realData[i].open < realData[i].close ? 'green' : 'red'
    ohlc.push([
      timestampDate, // the date
      realData[i].open > 0 ? realData[i].open : null, // open
      realData[i].high > 0 ? realData[i].high : null, // high
      realData[i].low > 0 ? realData[i].low : null, // low
      realData[i].close > 0 ? realData[i].close : null, // close
    ])
    // console.log('ohlc', ohlc);
    volume.push([
      timestampDate, // the date
      realData[i].volume > 0 ? realData[i].volume : null, // the volume
      volumeColor,
    ])
  }

  const [range, setRange] = useState(null)

  useEffect(() => {
    if (range !== null) {
      dispatch(userActions.setHighChRange(range))
    }
  }, [dispatch, range])

  const options = {
    chart: {
      height: windowWidth > 991 ? (9 / 16) * 100 + '%' : 600, // 16:9 ratio
      backgroundColor: userDarkMode ? '#191B20' : 'white',
      style: { fontFamily: 'CircularStd' },
    },
    rangeSelector: {
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
          events: {
            click: function () {
              setRange(0)
            },
          },
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
          events: {
            click: function () {
              setRange(1)
            },
          },
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          events: {
            click: function () {
              setRange(2)
            },
          },
        },
        {
          type: 'ytd',
          text: 'YTD',
          events: {
            click: function () {
              setRange(3)
            },
          },
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          events: {
            click: function () {
              setRange(4)
            },
          },
        },
        {
          type: 'all',
          text: 'All',
          events: {
            click: function () {
              setRange(5)
            },
          },
        },
      ],
      enabled: true,
      allButtonsEnabled: true,
      selected: userHighChRange !== null ? userHighChRange : 4,
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 4,
        style: {
          color: userDarkMode ? '#CCCCCC' : '#191B20',
          fontWeight: 'bold',
          fontSize: '13px',
        },
        states: {
          hover: {
            fill: userDarkMode ? 'black' : '#CCCCCC',
          },
          select: {
            fill: '#3377C6',
            style: {
              color: 'white',
            },
          },
          // disabled: { ... }
        },
      },

      inputBoxBorderColor: 'transparent',
      inputStyle: {
        color: '#3377C6',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadSVG', 'printChart'],
        },
      },
    },

    title: {
      // text: selectedCurr.code + '/' + chartQuoteCurr.toUpperCase(),
      text: '',
      align: 'left',
      style: {
        color: userDarkMode ? '#FAFAFA' : 'black',
        'font-size': '21px',
        'font-weight': '900',
      },
    },

    xAxis: {
      lineColor: userDarkMode ? '#303030' : '#CACACA',
    },

    yAxis: [
      {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'OHLC',
        },
        height: '80%',
        lineWidth: 2,
        resize: {
          enabled: true,
        },
      },
      {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'Volume',
        },
        top: '65%',
        height: '35%',
        offset: 0,
        lineWidth: 2,
      },
    ],

    tooltip: {
      split: true,
    },

    navigator: {
      outlineColor: userDarkMode ? '#303030' : '#CACACA',
      xAxis: {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
      },
      series: {
        lineWidth: 0,
        fillOpacity: 0,
      },
    },

    scrollbar: {
      barBackgroundColor: '#CACACA',
      barBorderRadius: 0,
      barBorderWidth: 0,
      buttonBackgroundColor: userDarkMode ? '#303030' : '#CACACA',
      buttonBorderWidth: 0,
      buttonBorderRadius: 2,
      buttonArrowColor: userDarkMode ? 'white' : 'black',
      trackBackgroundColor: 'none',
      trackBorderWidth: 1,
      trackBorderRadius: 2,
      trackBorderColor: userDarkMode ? '#303030' : '#CACACA',
    },

    series: [
      {
        type: 'candlestick',
        name: selectedMarket.exchName + ' ' + selectedMarket.displayName,
        data: ohlc,
        color: '#FF4242',
        upColor: '#50AF7B',
        lineColor: '#FF4242',
        upLineColor: '#50AF7B',
        // dataGrouping: {
        //   units: groupingUnits
        // }
      },
      {
        type: 'column',
        name: 'Volume',
        data: volume,
        color: '#3377C6',
        yAxis: 1,
        // dataGrouping: {
        //   units: groupingUnits
        // }
      },
    ],
  }

  let vol24 = selectedMarket.volume24
    ? selectedMarket.volume24 * btcExchRate
    : 0

  if (selectedMarket !== false) {
    return (
      <>
        <MetaData page="marketSlug" param={{exchName: selectedMarket.exchName, marketName: selectedMarket.marketName, locationExchSlug}}  />
        <PageWrapper headerDark footerDark>
          <Section
            bg={userDarkMode ? 'black' : 'bg'}
            className="pb-0"
            style={{ minHeight: '300px' }}
          >
            <Container className={windowWidth < 991 ? 'mt-5' : 'mt-3'}>
              <Row>
                <Col className="d-flex justify-content-between my-2" lg="12">
                  <Link
                    to={`/${lang}/markets/${
                      selectedMarket.prevSlug
                    }/${selectedMarket.prevMarketName.toLowerCase()}/`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {'← ' + selectedMarket.prevDisplayName}{' '}
                      {selectedMarket.prevSlugName}
                    </span>
                  </Link>
                  <Link
                    to={`/${lang}/markets/${
                      selectedMarket.nextSlug
                    }/${selectedMarket.nextMarketName.toLowerCase()}/`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {selectedMarket.nextSlugName}{' '}
                      {selectedMarket.nextDisplayName + ' →'}
                    </span>
                  </Link>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="12">
                  <Box className="d-flex align-items-center" mb={4}>
                    <Box className="mt-3 d-flex align-items-baseline">
                      <img
                        className={styles.exchangeLogo}
                        src={
                          process.env.WWW_URL +
                          `/assets/img/exchange/${selectedMarket.exchCode}-icon.png`
                        }
                        alt=""
                      />
                      <Title
                        color={userDarkMode ? '#FAFAFA' : 'dark'}
                        className="ml-3 mb-0"
                        variant="hero-smaller"
                      >
                        {selectedMarket.exchName} {selectedMarket.displayName}
                      </Title>
                      <Text
                        style={{
                          fontSize: windowWidth > 991 ? '20px' : '12px',
                        }}
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        className="ml-2"
                      >
                        {selectedMarket.baseCurrencyName} /{' '}
                        {selectedMarket.quoteCurrencyName}{' '}
                      </Text>
                    </Box>
                  </Box>
                </Col>
              </Row>
              <Row
                className="mb-3"
                style={{ justifyContent: 'space-between' }}
              >
                <Col>
                  <Text
                    variant="small"
                    color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                  >
                    {t('tables.volume24')}
                  </Text>
                  <Text
                    color={userDarkMode ? 'light' : 'dark'}
                  >
                    {userCurrSign}{' '}
                    {vol24
                      ? vol24.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })
                      : ''}
                  </Text>
                </Col>
                {selectedMarket.last ? (
                  <Col>
                    <Text
                      variant="small"
                      color={userDarkMode ? '#FAFAFA' : 'darkShade'}
                    >
                      {t('tables.price')}
                    </Text>
                    <Text
                      color={userDarkMode ? 'light' : 'dark'}
                    >
                      {currSign ? currSign : '$'}{' '}
                      {selectedMarket.last < 1
                        ? selectedMarket.last.toLocaleString(undefined, {
                            minimumFractionDigits: 8,
                          })
                        : selectedMarket.last.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                    </Text>
                  </Col>
                ) : (
                  <Text mb={4}></Text>
                )}
                <Col className='text-right'>
                  <a
                    className={styles.aTagButton}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.LS_APP_URL}/markets/${
                      selectedMarket.exchCode
                    }/${locationMarketSlug.toUpperCase()}`}
                  >
                    {t('launch')}
                  </a>
                </Col>
              </Row>
                <Row>
                  {typeof ohlc[1] !== 'undefined' ? (
                    <Col lg="12" sm="6" xs="6">
                      <Text
                        style={{
                          fontSize: windowWidth > 991 ? '20px' : '12px',
                        }}
                        mb={4}
                        mt={3}
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        variant="small"
                      > 
                        {ohlc[ohlc.length - 1][1] === null &&
                        ohlc[ohlc.length - 2][1] === null
                          ? 'This is a newly discovered market, we are still collecting data…'
                          : null}
                      </Text>
                    </Col>
                  ) : null}
                {completed && realData.length > 1 ? (

                  
                  <Col lg="12">
                    <BoxShadow
                      style={{
                        backgroundColor: userDarkMode ? '#191B20' : 'white',
                      }}
                      mb={4}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                      />
                    </BoxShadow>
                  </Col>
                ) : completed && realData.length < 1 ? (
                  <Col lg="9">
                    <NoDataCard
                      style={{
                        backgroundColor: userDarkMode ? '#191B20' : 'light',
                      }}
                    />
                  </Col>
                ) : (
                  <Col className="text-center">
                    <Spinner
                      variant={userDarkMode ? 'light' : 'dark'}
                      animation="border"
                      className='my-5'
                    />
                  </Col>
                )}
              </Row>
            </Container>
          </Section>
          <Section bg={userDarkMode ? 'black' : 'bg'} className="pb-0">
            <Container>
              <Row className="mt-3">
                <Col lg="6">
                  <LinkedCard
                    style={{
                      backgroundColor: userDarkMode ? '#191B20' : 'white',
                    }}
                    to={`/${lang}/cryptocurrencies/${
                      quoteSlug
                        ? quoteSlug
                            .split('-')[0]
                            .toLowerCase()
                            .replaceAll(' ', '-') + '/'
                        : ''
                    }`}
                    className="mb-2 newsCard"
                  >
                    <Box mb={0}>
                      <img
                        className={styles.exchangeLogo}
                        src={
                          process.env.WWW_URL +
                          `/assets/img/currency/${selectedMarket.quoteCurrencyCode}.png`
                        }
                        alt=""
                        onError={e => {
                          e.target.onerror = null
                          e.target.src =
                            process.env.WWW_URL +
                            `/assets/img/currency/empty.png`
                        }}
                      />
                      <Title
                        color={userDarkMode ? 'primaryLight' : 'primary'}
                        variant="card"
                        className="mt-2"
                      >
                        {selectedMarket.quoteCurrencyName
                          ? selectedMarket.quoteCurrencyName +
                            ` (${selectedMarket.quoteCurrencyCode})`
                          : ''}
                      </Title>
                      <Text
                        className="mb-2"
                        variant="small"
                        color={userDarkMode ? 'light' : 'dark'}
                      >
                        {t(
                          `${selectedMarket.quoteCurrencyCode}`
                        ).includes('currenciesPage')
                          ? ''
                          : t(
                              `${selectedMarket.quoteCurrencyCode}`
                            )}
                      </Text>
                    </Box>
                  </LinkedCard>
                </Col>
                <Col lg="6">
                  <LinkedCard
                    style={{
                      backgroundColor: userDarkMode ? '#191B20' : 'white',
                    }}
                    to={`/${lang}/cryptocurrencies/${
                      baseSlug
                        ? baseSlug
                            .split('-')[0]
                            .toLowerCase()
                            .replaceAll(' ', '-') + '/'
                        : ''
                    }`}
                    className="mb-2"
                  >
                    <Box mb={0}>
                      <img
                        className={styles.exchangeLogo}
                        src={
                          process.env.WWW_URL +
                          `/assets/img/currency/${selectedMarket.baseCurrencyCode}.png`
                        }
                        alt=""
                        onError={e => {
                          e.target.onerror = null
                          e.target.src =
                            process.env.WWW_URL +
                            `/assets/img/currency/empty.png`
                        }}
                      />
                      <Title
                        color={userDarkMode ? 'primaryLight' : 'primary'}
                        variant="card"
                        className="mt-2"
                      >
                        {selectedMarket.baseCurrencyName
                          ? selectedMarket.baseCurrencyName +
                            ` (${selectedMarket.baseCurrencyCode})`
                          : ''}
                      </Title>
                      <Text
                        className="mb-2"
                        variant="small"
                        color={userDarkMode ? 'light' : 'dark'}
                      >
                        {t(
                          `${selectedMarket.baseCurrencyCode}`
                        ).includes('currenciesPage')
                          ? ''
                          : t(
                              `${selectedMarket.baseCurrencyCode}`
                            )}
                      </Text>
                    </Box>
                  </LinkedCard>
                </Col>
                <Col className="mt-5" lg="12">
                  {userSessionExpired === false ? (
                    <div>
                      <EditIcon />
                      <Button
                        onClick={() => setOpenTextArea(true)}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <Text color="#3377C6" variant="small">
                          {t(`suggestEdit`)}
                        </Text>
                      </Button>
                    </div>
                  ) : null}
                  {openTextArea === true ? (
                    <Row>
                      <Select
                        className="mb-3 mt-3"
                        isSearchable={false}
                        options={editDescOptions}
                        borderRadius={0}
                        defaultValue={editDescOptions[1]}
                        onChange={option => setEditType(option.value)}
                        components={{ IndicatorSeparator: () => null }}
                        userDarkMode={userDarkMode}
                      />
                      <Input
                        onChange={e => onChange(e)}
                        placeholder="Enter your suggestion..."
                      />
                      <Button
                        className="mt-3"
                        onClick={() => editCurrDesc(inputValue)}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <Text color="#3377C6" variant="small">
                          {t(`send`)}
                        </Text>
                      </Button>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </Container>
          </Section>
          <LatestNews />
        </PageWrapper>
      </>
    )
  } else return <Text>Loading...</Text>
}
export default MarketDetail
